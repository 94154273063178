import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import identityService from "../../../services/identityService";
interface UpdateProps {
  cardDetails: any
  onClose: () => void;
  setCardLoader: Dispatch<SetStateAction<boolean>>;
  cardLoader: boolean
}

const BankDetail: React.FC<UpdateProps> = ({ cardDetails, onClose, setCardLoader, cardLoader }) => {
  const {
    brand,
    exp_month,
    exp_year,
    id,
    last4,
  } = cardDetails

  const [modalStep, setModalStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardDefaultSuccessfully, setIsCardDefaultSuccessfully] = useState<boolean>((false))

  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => {
    setModalStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setModalStep((prevStep) => prevStep - 1);
  };

  const handleModalClose = () => {
    onClose();
  };

  const [visible, setVisible] = React.useState(false);


  const handleSetDefault = (e: any) => {
    if (e.target.checked) {
      setIsLoading(true)
      const payload = {
        payment_method_id: id
      }
      identityService.setMerchantCardDefault(payload).then((res) => {
        setIsLoading(false)
        setCardLoader(!cardLoader)
        if (res?.message) {
          setIsCardDefaultSuccessfully(true)
        }
      }).catch((err) => {
        setIsLoading(false)
      })
    }
  }


  const handleDeleteCard = () => {
    setIsLoading(true)
    const payload = {
      payment_method_id: id
    }
    identityService.deleteMerchantCard(payload).then((res) => {
      if (!res?.error) {
        setIsLoading(false)
        setCardLoader(!cardLoader)
        handleModalClose()
      }

    }).catch((err) => {
      setIsLoading(false)
    })
  }


  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="bankDetPopBox">
              <div className="popupHd tac">Card Details</div>
              <div className="blBox">
                {/* <img src="/images/citiBankCircle.svg" alt="bank logo" /> */}
                <p>Card Details</p>
              </div>
              <div className="bkDetCont">
                <p>Card Details</p>
                <table>
                  <tr>
                    <td>Card Number</td>
                    <td>:XXXX XXXX XXXX {last4}</td>
                  </tr>
                  <tr>
                    <td>Card Type</td>
                    <td>:{brand}</td>
                  </tr>
                  <tr>
                    <td>Expiry</td>
                    <td>:{exp_month}/{exp_year}</td>
                  </tr>
                </table>
              </div>
              <div className="accTogg">
                <div className="accToggInner">
                  <div className="toggTxt">
                    <p className="p1">Default Card</p>
                    <p className="p2">Default Card Will Send Money</p>
                  </div>
                  <div className="toggBtn d-flex align-items-center" >
                    {isLoading ? <div className="spinner-border spinner-border-sm" style={{ position: "relative", left: "60px" }} role="status">
                      <span className="sr-only p-0"></span>
                    </div> : ""}
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        disabled={isLoading || cardDetails?.default || isCardDefaultSuccessfully}
                        onChange={handleSetDefault}
                        checked={cardDetails?.default || isCardDefaultSuccessfully}
                      />
                    </Form>
                  </div>
                </div>
                <div className="btnDelWrp">
                  <Button disabled={cardDetails?.default || isCardDefaultSuccessfully} className="delBank" onClick={handleNextStep}>Delete Card</Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
            <a className="closePopup" onClick={handleModalClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Delete Card?</div>
            <div className="tac loginText">Are you sure you want to delete this card?</div>
            <ul className="popBtnsWrp">
              <li>
                <Button className="loginBtn block"
                  disabled={isLoading}
                  onClick={handleDeleteCard}
                >{isLoading ? <div className="spinner-border" role="status">
                  <span className="sr-only p-0"></span>
                </div> : "Delete Card"}</Button>
              </li>
              <li>
                <Button className="loginBtn block cancel" onClick={handleModalClose}>Cancel</Button>
              </li>
            </ul>
          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
            <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
            <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
            <div className="popupHd tac">Card Deleted Successfully!</div>
          </div>
        )}
      </div>
    </>
  );
};

export default BankDetail;
