import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe('pk_test_51NUArmSGpkdTpRlzQGF7t8WWIB0pcTp82NX4pRgK9xkppUIYtFrhjkX2BaQrFcgiK6DPK0g62T90GHS9v8pWHGlA00uWBeFLt0')

root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
