import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { merchantLogin } from '../../../redux/features/auth/authAction'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ForgotPassword from '../../../component/Merchant/MerchantForgotPassword';
import ResetPassword from '../../../component/Admin/AdminResetPassword';
import { getLocalAccessToken, getUser, getUserType, setUser } from '../../../services/token.service';
import { emailRegex, phoneNumberRegex } from '../../../utils/regex';
import { getKYCStatus } from '../../../redux/features/KYC/kycAction';
import EmailVerify from '../../../component/Merchant/MerchantEmailVerify';
import PhoneVerify from '../../../component/Merchant/MerchantPhoneVerify';
import VerifyModal from '../../../component/Merchant/MerchantVerifyPopup';


interface merchantLoginInterface {
  email: string;
  phone_number: string;
  password: string;
  errors: any
}

const initialLoginState: merchantLoginInterface = {
  email: "",
  phone_number: "",
  password: "",
  errors: ""
}


const Index = () => {

  // toggling for forget password modal
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // toggling for reset password modal
  const [show2, setShow2] = useState<boolean>(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false)
  const [loginFormData, setLoginFormData] = useState<merchantLoginInterface>(initialLoginState)
  const [errorVisible, setErrorVisible] = useState({ error: false, message: "" })
  const [emailValue, setEmailValue] = useState<any>(null)
  const [phone, setPhone] = useState<any>(null)
  const [emailVerifyModel, setEmailVerifyModel] = useState<boolean>(false)
  const [phoneVerifyModel, setPhoneVerifyModel] = useState<boolean>(false)
  const [verifyModel, setVerifyModel] = useState<boolean>(false)
  const [isVerifyPopupForEmailRes, setIsVerifyPopupForEmailRes] = useState(false)
  const [isVerifyPopupForPhoneRes, setIsVerifyPopupForPhoneRes] = useState(false)
  const [isEmailVerify, setIsEmailVerify] = useState(false)

  const dispatch: AppDispatch = useDispatch()
  const authData = useSelector((state: any) => state.auth)
  const navigate = useNavigate();

  const { email, password, errors } = loginFormData

  const handleCloseEmailVerifyModal = () => setEmailVerifyModel(false);
  const handleClosePhoneVerifyModal = () => {
    setPhoneVerifyModel(false)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const isTokenExist = getLocalAccessToken()
  const userType = getUserType()
  if (isTokenExist && userType === "Merchant") {
    return <Navigate to="/merchant/dashboard" />
  } else if (isTokenExist && userType === "Admin") {
    return <Navigate to="/adlg" />
  }

  // add input data into state variable
  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "emailOrPhone") {
      if (phoneNumberRegex.test(value)) {
        setPhone(value);
      } else if (emailRegex.test(value)) {
        setEmailValue(value)
      }
    }
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  }

  // handle form validation
  let handleValidation = () => {
    let emailOrPhoneEmpty = "";
    let passwordEmpty = "";
    let formIsValid = true;

    if (!emailValue.trim()) {
      emailOrPhoneEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!phoneNumberRegex.test(emailValue) && !emailRegex.test(emailValue)) {
      emailOrPhoneEmpty = "phone format is not valid.";
      formIsValid = false;
    }

    if (!password.trim()) {
      passwordEmpty = "Please enter password.";
      formIsValid = false;
    }

    setLoginFormData({
      ...loginFormData,
      errors: {
        emailOrPhoneEmpty,
        passwordEmpty,
        formIsValid
      },
    });
    return formIsValid;
  };


  // handle form submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setErrorVisible({ error: false, message: "" })
    const isFormValid = handleValidation()
    if (!isFormValid) {
      return
    }
    setIsLoginLoading(true)
    let payloadData = {}
    if (emailRegex.test(emailValue)) {
      payloadData = {
        email: emailValue,
        phone_number: null,
        password,
      }

    } else if (phoneNumberRegex.test(phone)) {
      payloadData = {
        email: null,
        phone_number: phone,
        password,
      }
    }

    const data = payloadData
    dispatch(merchantLogin(data)).then(async (res: any) => {

      if (res.payload.access_token) {
        const user = {
          accessToken: res.payload.access_token,
          refreshToken: res.payload.refresh_token,
          userType: "Merchant"
        }
        await setUser(user)
        localStorage.setItem("customerId", res.payload.data.customer_id)
        localStorage.setItem("walletId", res.payload.data.wallet_id)
        navigate("/merchant/dashboard")
        navigate(0)
      } else {
        setIsLoginLoading(false)
        if (res.payload?.data?.email_status === false) {
          setVerifyModel(true)
        } else if (res.payload?.data?.mobile_status === false) {
          setIsVerifyPopupForPhoneRes(true)
        }
        setErrorVisible({ error: true, message: res.payload?.message })
      }
    }).catch((err) => {
      console.log(err)
      setIsLoginLoading(false)
    })
  }

  useEffect(() => {
    if (isVerifyPopupForEmailRes) {
      setEmailVerifyModel(true)
    }
  }, [isVerifyPopupForEmailRes])

  useEffect(() => {
    if (isEmailVerify || authData?.data?.mobile_status === false) {
      setPhoneVerifyModel(true)
    }
  }, [isVerifyPopupForPhoneRes, isEmailVerify])

  return (
    <>
      <div className="d-flex flex-wrap loginCon">
        <div
          className="loginLeft"
          style={{ background: "url(images/login-bg.jpg) no-repeat left bottom" }}
        >
          <div className="loginTop d-flex flex-wrap align-items-center justify-content-center">
            <div className="logininner">
              <div className="loginLogo">
                <img src="./images/logo.png" alt="" />
              </div>
              <h1 className="loginHd">Log in to your account</h1>
              <div className="loginText">
                Welcome back! Please enter your details.
              </div>
              <form onSubmit={handleSubmit} onChange={(e: any) => handleFormChange(e)}>
                <ul className="loginForm p-0 m-0">
                  <li>
                    <input
                      name="emailOrPhone"
                      type="text"
                      placeholder="Enter email or phone"
                      className="formInput"
                    />
                  </li>
                  <span style={{ color: "red" }}>
                    {errors && errors?.emailOrPhoneEmpty}
                  </span>
                  <li>
                    <div className="pr">
                      <input
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        // onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        className="formInput password"
                      />
                      <span className="material-icons-outlined eye" onClick={togglePasswordVisibility}>
                        {isActive ? 'visibility_off' : 'visibility'}
                      </span>
                    </div>
                    <div className="erroeMessage" style={{ display: errorVisible.error ? "block" : "none" }}>
                      {errorVisible.message}
                    </div>
                  </li>
                  <span style={{ color: "red" }}>
                    {errors && errors?.passwordEmpty}
                  </span>
                  <li className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="rem">
                      <label className='d-flex align-items-center'><Form.Check className='checkbox me-2 mb-1' /> Remember me</label>
                    </div>
                    <div>
                      <a
                        className="fpLink"
                        onClick={handleShow}>
                        Forgot Password?
                      </a>
                    </div>
                  </li>
                  <li>
                    <button className="loginBtn" disabled={isLoginLoading}>
                      {isLoginLoading ? "Loading..." : "Login"}
                    </button>
                  </li>
                  <li className="flexBox itemCenter justifyContentBetween">
                    <div className="rem">
                      <label>
                        <input type="checkbox" className="checkInpt" /> Don't have an account?
                      </label>
                    </div>
                    <div>
                      <Link to="/register">
                        <a
                          className="fpLink showPopup"
                        >
                          Sign Up
                        </a>
                      </Link>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div
          className="loginBg"
          style={{ background: "url(images/login-img.jpg) no-repeat center top" }}
        />
      </div>

      <Modal show={show}>
        <ForgotPassword
          onClose={handleClose} />
      </Modal>

      <Modal show={emailVerifyModel}>
        <EmailVerify handleVerify={setIsEmailVerify} onClose={handleCloseEmailVerifyModal} user_id={authData?.data?.userid} heading={"Email Verification"} />
      </Modal>

      <Modal show={verifyModel}>
        <VerifyModal handleVerify={setIsVerifyPopupForEmailRes} handleModalClose={setVerifyModel} heading="Verification" />
      </Modal>

      <Modal show={phoneVerifyModel}>
        <PhoneVerify onClose={handleClosePhoneVerifyModal} user_id={authData?.data?.userid} heading={"Phone Verification"} />
      </Modal>



    </>
  )
}

export default Index