import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import identityService from "../../../services/identityService";
interface UpdateProps {
  bankDetails: any
  onClose: () => void;
  setBankLoader: Dispatch<SetStateAction<boolean>>;
  bankLoader: boolean
}

const BankDetail: React.FC<UpdateProps> = ({ bankDetails, onClose, setBankLoader, bankLoader }) => {

  const {
    account,
    account_holder_name,
    account_holder_type,
    account_type,
    available_payout_methods,
    bank_name,
    country,
    currency,
    default_for_currency,
    fingerprint,
    future_requirements,
    id,
    last4,
    metadata,
    object,
    requirements,
    routing_number,
    status,
  } = bankDetails

  const [modalStep, setModalStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBankDefaultSuccessfully, setIsBankDefaultSuccessfully] = useState<boolean>((false))

  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => {
    setModalStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setModalStep((prevStep) => prevStep - 1);
  };

  const handleModalClose = () => {
    onClose();
  };

  const [visible, setVisible] = React.useState(false);


  const handleSetDefault = (e: any) => {
    if (e.target.checked) {
      setIsLoading(true)
      const payload = {
        external_account_id: id
      }
      identityService.setMerchantBankDefault(payload).then((res) => {
        setIsLoading(false)
        setBankLoader(!bankLoader)
        if (res?.data) {
          setIsBankDefaultSuccessfully(true)
        }
      }).catch((err) => {
        setIsLoading(false)
      })
    }
  }


  const handleDeleteAccount = () => {
    setIsLoading(true)
    const payload = {
      external_account_id: id
    }
    identityService.deleteMerchantBank(payload).then((res) => {
      if (!res?.error) {
        setIsLoading(false)
        setBankLoader(!bankLoader)
        handleModalClose()
      }

    }).catch((err) => {
      setIsLoading(false)
    })
  }


  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="bankDetPopBox">
              <div className="popupHd tac">Bank Details</div>
              <div className="blBox">
                {/* <img src="/images/citiBankCircle.svg" alt="bank logo" /> */}
                <p>Bank Details</p>
              </div>
              <div className="bkDetCont">
                <p>Account Details</p>
                <table>
                  <tr>
                    <td>Account Holder</td>
                    <td>:John Deo</td>
                  </tr>
                  <tr>
                    <td>A/c No.</td>
                    <td>:123456789</td>
                  </tr>
                  <tr>
                    <td>Bank Code</td>
                    <td>:ABC123</td>
                  </tr>
                  <tr>
                    <td>Branch Code</td>
                    <td>:ABC123</td>
                  </tr>
                  <tr>
                    <td>SWIFT/BIC Code</td>
                    <td>:ABC123</td>
                  </tr>
                </table>
              </div>
              <div className="accTogg">
                <div className="accToggInner">
                  <div className="toggTxt">
                    <p className="p1">Default Account</p>
                    <p className="p2">Default Bank Will Receive Money</p>
                  </div>
                  <div className="toggBtn d-flex align-items-center" >
                    {isLoading ? <div className="spinner-border spinner-border-sm" style={{ position: "relative", left: "60px" }} role="status">
                      <span className="sr-only p-0"></span>
                    </div> : ""}
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        disabled={isLoading || default_for_currency || isBankDefaultSuccessfully}
                        onChange={handleSetDefault}
                        checked={default_for_currency || isBankDefaultSuccessfully}
                      />
                    </Form>
                  </div>
                </div>
                <div className="btnDelWrp">
                  <Button disabled={default_for_currency || isBankDefaultSuccessfully} className="delBank" onClick={handleNextStep}>Delete Bank</Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
            <a className="closePopup" onClick={handleModalClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Delete Bank?</div>
            <div className="tac loginText">Are you sure you want to delete this bank?</div>
            <ul className="popBtnsWrp">
              <li>
                <Button className="loginBtn block"
                  disabled={isLoading}
                  onClick={handleDeleteAccount}
                >{isLoading ? <div className="spinner-border" role="status">
                  <span className="sr-only p-0"></span>
                </div> : "Delete Bank"}</Button>
              </li>
              <li>
                <Button className="loginBtn block cancel" onClick={handleModalClose}>Cancel</Button>
              </li>
            </ul>
          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
            <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
            <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
            <div className="popupHd tac">Bank Deleted Successfully!</div>
          </div>
        )}
      </div>
    </>
  );
};

export default BankDetail;
