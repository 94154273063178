import React, { useState } from 'react'
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import DocumentPreview from '../../component/DocumentPreview';

interface UserDetailProps {
  data: any
}

const UserDetail: React.FC<UserDetailProps> = ({ data }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentTimezone = moment.tz.guess();


  return (
    <>
      <a className="viewLink" onClick={handleShow}>View Details</a>
      <Modal show={show}>
        <div>
          <div className="receiptBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd">User Details</div>
            <div className="userName flexBox nowrap itemCenter"><img src={data.ew_selfie_url} alt="" className="roundImg" /> <span>{data.ew_full_name}</span></div>
            <ul className="userInfo">
              <li>
                <span className="left">Create At</span>
                <span className="right">{moment.utc(data?.created_at).tz(currentTimezone).format('DD MMM YYYY | hh:mm A')}</span>
              </li>
              <li>
                <span className="left">User ID</span>
                <span className="right">{data?.ew_user_id}</span>
              </li>
              <li>
                <span className="left">Phone Number</span>
                <span className="right">{data?.ew_phone_number || "-"}</span>
              </li>
              <li>
                <span className="left">Audit Trial</span>
                <span className="right">{data?.ew_audit_trail || "-"}</span>
              </li>
              <li>
                <span className="left">City</span>
                <span className="right">{data?.ew_city}</span>
              </li>
              <li>
                <span className="left">Country</span>
                <span className="right">{data?.ew_country}</span>
              </li>
              <li>
                <span className="left">Status</span>
                <span className="right">{data?.ew_eKYC_verification_status}</span>
              </li>
              <li>
                <span className="left">Document</span>
                <span className="right">{<DocumentPreview preview={data?.ew_document_url} />}</span>
              </li>

              <li>
                <span className="left">Email</span>
                <span className="right">{data?.ew_email}</span>
              </li>
              <li>
                <span className="left">Document Id Type</span>
                <span className="right">{data?.ew_id_type}</span>
              </li>
              <li>
                <span className="left">Income Tax Id</span>
                <span className="right">{data?.ew_income_tax_id}</span>
              </li>
              <li>
                <span className="left">Document Id Type</span>
                <span className="right">{data?.ew_id_type}</span>
              </li>
              <li>
                <span className="left">Postal Code</span>
                <span className="right">{data?.ew_postal_code}</span>
              </li>

              <li>
                <span className="left">Selfie</span>
                <span className="right">{<DocumentPreview preview={data?.ew_selfie_url} />}</span>
              </li>
              <li>
                <span className="left">State</span>
                <span className="right">{data?.ew_state}</span>
              </li>
              <li>
                <span className="left">Street Address</span>
                <span className="right">{data?.ew_street_Address}</span>
              </li>
              {/* <li className="total">
                <span className="left">Action</span>
                <span className="right"><a className="viewLink" onClick={handleShow}>Block</a></span>
              </li> */}
            </ul>
          </div>
        </div>
      </Modal>
      {/* <Modal show={show}>
        <BlockUser onClose={handleClose} />
      </Modal> */}
    </>
  )
}

export default UserDetail