import { Elements, CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSearchParams } from 'react-router-dom';
import './AddCreditCardForm.css'

const stripePromise = loadStripe('pk_test_51NUArmSGpkdTpRlzQGF7t8WWIB0pcTp82NX4pRgK9xkppUIYtFrhjkX2BaQrFcgiK6DPK0g62T90GHS9v8pWHGlA00uWBeFLt0');

// const stripePromise = loadStripe('pk_live_51PiKctJk5A0TQMIcT0Ms60EPXF2jU0BmZa3rgnMEfOy8detWu75nJB8OkpyYVseDh2h6uzGnDqDyynle0fqYlZ0J0019YHVCIj');




const CheckoutForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("usertoken")
    const userToken = token
    const stripe: any = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        // const cardElement = elements.getElement(CardElement)

        // const { token, error } = await stripe.createToken(cardElement);
        const cardElement = elements.getElement(CardElement);
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            console.error(error);
            alert('Error creating payment token');
        } else {
            // Send the token to your backend
            const response = await fetch(`https://identity.ewallet.flexsin.org/merchants/attach-card`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken },
                // body: JSON.stringify({ token: token.id }),
                body: JSON.stringify({ payment_method_id: paymentMethod.id }),
            });
            const result = await response.json();

            if (result.error) {
                console.error(result.error);
                alert('Error processing payment');
            } else {
                alert('Payment successful');
            }
        }
    };

    return (
        <div className="payment-form-container">
            <form className="payment-form" onSubmit={handleSubmit}>
                <label>
                    Card Number
                    <CardElement className="StripeElement" />
                </label>
                {/* <label>
                    Expiration Date
                    <CardExpiryElement className="StripeElement" />
                </label>
                <label>
                    CVC
                    <CardCvcElement className="StripeElement" />
                </label> */}
                <button type="submit" disabled={!stripe}>
                    Add Card
                </button>
            </form>
        </div>
    );
};

const PaymentForm = () => (
    <Elements stripe={stripePromise}>
        <CheckoutForm />
    </Elements>
);

export default PaymentForm;
