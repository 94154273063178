import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from '../../redux/features/auth/authAction'
import { AppDispatch } from "../../redux/store"
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import EmailVerify from '../../component/Merchant/MerchantEmailVerify';
import { getLocalAccessToken, getUser, getUserType } from '../../services/token.service';
import identityService from '../../services/identityService';
import PhoneVerify from '../../component/Merchant/MerchantPhoneVerify';
import VerifyModal from '../../component/Merchant/MerchantVerifyPopup';

interface regitrationInterface {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  confirmPassword: string;
  securityQuestionid: string,
  answer: string,
  errors: any;
}

const initialRegistrationState: regitrationInterface = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  password: "",
  confirmPassword: "",
  securityQuestionid: "",
  answer: "",
  errors: ""
}

const Index = () => {
  const dispatch: AppDispatch = useDispatch()
  const [registrationFormData, setRegistrationFormData] = useState<regitrationInterface>(initialRegistrationState)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [errorVisible, setErrorVisible] = useState({ error: false, message: "" })
  const [emailVerifyModel, setEmailVerifyModel] = useState<boolean>(false)
  const [phoneVerifyModel, setPhoneVerifyModel] = useState<boolean>(false)
  const [securityQuestion, setSecurityQuestion] = useState([])
  const [isEmailVerify, setIsEmailVerify] = useState(false)
  const [verifyModel, setVerifyModel] = useState<boolean>(false)
  const [isVerifyPopupForEmailRes, setIsVerifyPopupForEmailRes] = useState(false)
  const [isVerifyPopupForPhoneRes, setIsVerifyPopupForPhoneRes] = useState(false)
  // toggling for reset password modal
  // const [show2, setShow2] = useState<boolean>(false);
  const handleCloseEmailVerifyModal = () => setEmailVerifyModel(false);
  const handleClosePhoneVerifyModal = () => {
    setPhoneVerifyModel(false)
  }
  // const handleShow2 = () => setShow2(true);

  const { firstName, lastName, email, phoneNo, password, confirmPassword, securityQuestionid, answer, errors } = registrationFormData
  const registerData = useSelector((state: any) => state.auth)
  // const navigate = useNavigate();
  const isTokenExist = getLocalAccessToken()
  const userType = getUserType()
  useEffect(() => {
    identityService.getSecurityQuestion().then((res) => {
      setSecurityQuestion(res?.data)
    }).catch((err) => {
    })
  }, [])

  if (isTokenExist && userType === "Admin") {
    return <Navigate to="/admin/dashboard" />
  } else if (isTokenExist && userType === "Merchant") {
    return <Navigate to="/" />
  }

  // add form value in state variable
  const handleFormChange = (event: any) => {
    const { name, value } = event.target;
    setRegistrationFormData({
      ...registrationFormData,
      [name]: value,
    });
  }

  // handle form validation
  let handleValidation = () => {
    let firstNameEmpty = "";
    let lastNameEmpty = "";
    let emailEmpty = "";
    let phoneNoEmpty = "";
    let passwordEmpty = "";
    let confirmPasswordEmpty = "";
    let securityQuestionEmpty = "";
    let answerEmpty = "";
    let formIsValid = true;

    if (!firstName.trim()) {
      firstNameEmpty = "Please enter first name.";
      formIsValid = false;
    }

    if (!lastName.trim()) {
      lastNameEmpty = "Please enter last name.";
      formIsValid = false;
    }
    if (!email.trim()) {
      emailEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailEmpty = "Email format is not valid.";
      formIsValid = false;
    }
    if (!phoneNo.trim()) {
      phoneNoEmpty = "Please enter phone no.";
      formIsValid = false;
    }

    if (!password.trim()) {
      passwordEmpty = "Please enter password.";
      formIsValid = false;
    }
    if (!confirmPassword.trim()) {
      confirmPasswordEmpty = "Please enter confirm password.";
      formIsValid = false;
    }

    if (!securityQuestionid) {
      securityQuestionEmpty = "Please select security question.";
      formIsValid = false;
    }

    if (!answer.trim()) {
      answerEmpty = "Please enter security answer.";
      formIsValid = false;
    }

    setRegistrationFormData({
      ...registrationFormData,
      errors: {
        firstNameEmpty,
        lastNameEmpty,
        emailEmpty,
        phoneNoEmpty,
        passwordEmpty,
        confirmPasswordEmpty,
        securityQuestionEmpty,
        answerEmpty,
        formIsValid,
      },
    });
    return formIsValid;
  };

  // handle register submit form 
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setErrorVisible({ error: false, message: "" })
    const isFormValidate = handleValidation()
    if (!isFormValidate) {
      return
    }
    setIsSubmitLoading(true)
    const data = {
      user_data: {
        ew_first_name: firstName,
        ew_last_name: lastName,
        ew_email: email,
        ew_phone_number: phoneNo,
        ew_password: password,
        ew_confirm_password: confirmPassword,
        ew_user_type: "Merchant"
      },
      security_questions_input: {
        ew_security_question_id: parseInt(securityQuestionid),
        ew_security_answer: answer
      },
      terms_and_conditions: {
        ew_terms_and_conditions: true
      }
    }
    dispatch(registerUser(data)).then((data: any) => {
      setIsSubmitLoading(false)

      if (data.payload?.data) {
        setVerifyModel(true)
      }
    }).catch((err) => {
      setIsSubmitLoading(false)
      console.log(err)
    })
  }

  useEffect(() => {
    if (isVerifyPopupForEmailRes) {
      setEmailVerifyModel(true)
    }
  }, [isVerifyPopupForEmailRes])

  useEffect(() => {
    if (isEmailVerify) {
      setPhoneVerifyModel(true)
    }
  }, [isEmailVerify])

  return (
    <>
      <div className="flexBox loginCon">
        <div
          className="loginLeft"
          style={{ background: "./images/login-bg.jpg" }}
        >
          <div className="loginTop flexBox itemCenter justifyContentCenter">
            <div className="logininner">
              <div className="loginLogo">
                <img src="images/logo.png" alt="test" />
              </div>
              <h1 className="loginHd">Register to your account</h1>
              <div className="loginText">
                Welcome back! Please enter your details.
              </div>
              <form onSubmit={handleSubmit} onChange={(e: any) => handleFormChange(e)}>
                <ul className="loginForm">
                  <li>
                    <input
                      name="firstName"
                      type="text"
                      placeholder="Enter first name"
                      className="formInput"
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors?.firstNameEmpty}
                    </span>
                  </li>
                  <li>
                    <input
                      name="lastName"
                      type="text"
                      placeholder="Enter last name"
                      className="formInput"
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors?.lastNameEmpty}
                    </span>
                  </li>
                  <li>
                    <input
                      name="email"
                      type="text"
                      placeholder="Enter email"
                      className="formInput"
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors?.emailEmpty}
                    </span>
                  </li>
                  <li>
                    <input
                      name="phoneNo"
                      type="text"
                      placeholder="Enter phone number"
                      className="formInput"
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors?.phoneNoEmpty}
                    </span>
                  </li>
                  <li>
                    <div className="pr">
                      <input
                        name="password"
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter Password"
                        className="formInput password"
                      />
                      <span onClick={() => setIsPasswordVisible(!isPasswordVisible)} className="material-icons-outlined show eye">
                        visibility
                      </span>
                    </div>
                    <span style={{ color: "red" }}>
                      {errors && errors?.passwordEmpty}
                    </span>
                  </li>

                  <li>
                    <div className="pr">
                      <input
                        name="confirmPassword"
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        placeholder="Enter confirm Password"
                        className="formInput password"
                      />
                      <span onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)} className="material-icons-outlined show eye">
                        visibility
                      </span>
                    </div>
                    <span style={{ color: "red" }}>
                      {errors && errors?.confirmPasswordEmpty}
                    </span>
                  </li>

                  <li >
                    <div className="questAns">
                      <div className="frmGrp">
                        <select defaultValue={securityQuestionid} name="securityQuestionid" id="" className="formInput">
                          <option value="">Select security question</option>
                          {securityQuestion.map((data: any) => {
                            return (
                              <option value={data.id}>{data.ew_security_question}</option>
                            )
                          })}
                        </select>
                      </div>
                      <span style={{ color: "red" }}>
                        {errors && errors?.securityQuestionEmpty}
                      </span>
                      <div className="frmGrp">
                        <input
                          placeholder="type your answer..."
                          type="text"
                          className="formInput"
                          defaultValue={answer}
                          name="answer"
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {errors && errors?.answerEmpty}
                      </span>
                      {/* <div>

                        <Button onClick={handleSubmitEmailVerifyOTP} className="loginBtn showPopup">{isLoading ? "Loading..." : "Submit"}</Button>
                    </div> */}

                    </div>

                  </li>



                  <div className="erroeMessage" style={{ display: registerData.loading ? "none" : registerData.error ? "block" : "none" }}>
                    {registerData.message}
                  </div>
                  <div className="erroeMessage" style={{ display: !registerData.data ? "none" : !registerData.error ? "block" : "none" }}>
                    {registerData.message}
                  </div>
                  <li>
                    <button
                      disabled={isSubmitLoading}
                      className="loginBtn"
                    // onClick={handleClick}
                    >
                      {isSubmitLoading ? "Loading..." : "Register"}
                    </button>
                  </li>
                  <li className="flexBox itemCenter justifyContentBetween">
                    <div className="rem">
                      <label>
                        <input type="checkbox" className="checkInpt" /> Already have an account?
                      </label>
                    </div>
                    <div>
                      <Link to="/">
                        <a
                          className="fpLink showPopup"
                        >
                          Sign In
                        </a>
                      </Link>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div
          className="loginBg"
          style={{ background: "url(images/login-img.jpg) no-repeat center top" }}
        />
      </div>
      <Modal show={verifyModel}>
        <VerifyModal handleVerify={setIsVerifyPopupForEmailRes} handleModalClose={setVerifyModel} heading="Verification" />
      </Modal>

      <Modal show={emailVerifyModel}>
        <EmailVerify handleVerify={setIsEmailVerify} onClose={handleCloseEmailVerifyModal} user_id={registerData.data?.userid} heading={"Email Verification"} />
      </Modal>

      <Modal show={phoneVerifyModel}>
        <PhoneVerify onClose={handleClosePhoneVerifyModal} user_id={registerData.data?.userid} heading={"Phone Verification"} />
      </Modal>
    </>
  )
}

export default Index