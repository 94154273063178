import React, { useState } from 'react'
import Header from '../../component/Admin/AdminHeader';
import AdminLeftMenu from '../../component/Admin/AdminLeftMenu';
import MerchantLeftMenu from '../../component/Merchant/MerchantLeftMenu';
import Update from '../../component/Update';
import Delete from '../../component/Delete';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { getLocalAccessToken, getUserType, setUser } from '../../services/token.service';


const Index = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());

    const isTokenExist = getLocalAccessToken()
    const userType = getUserType()

    return (
        <>
            <div>
                {isTokenExist && userType === "Admin" ? <AdminLeftMenu /> : ""}
                {isTokenExist && userType === "Merchant" ? <MerchantLeftMenu /> : ""}

                <div className={isTokenExist && (userType === "Admin" || userType === "Merchant") ? "dbRight" : ""}>
                    <h1>Introduction</h1>
                    <p>
                        Welcome to E-wallet. We value your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and protect your information when you use our mobile e-wallet application.
                    </p>

                    <h2>1. Information We Collect</h2>

                    <h3>1.1 Personal Information</h3>
                    <ul>
                        <li><strong>Account Information:</strong> When you register, we collect your name, email address, phone number, and other necessary details.</li>
                        <li><strong>Identification:</strong> Information such as your government-issued ID, social security number, or other identification documents.</li>
                    </ul>

                    <h3>1.2 Transaction Information</h3>
                    <ul>
                        <li><strong>Payment Details:</strong> Information related to the payments you make and receive, including payment methods and transaction history.</li>
                        <li><strong>Financial Information:</strong> Bank account details, credit or debit card numbers, and other financial information required to process transactions.</li>
                    </ul>

                    <h3>1.3 Usage Information</h3>
                    <ul>
                        <li><strong>Device Information:</strong> We collect information about the device you use to access our app, including IP address, operating system, and browser type.</li>
                        <li><strong>Log Information:</strong> Details of your interactions with our app, such as login times, duration of sessions, and transaction activity.</li>
                    </ul>

                    <h3>1.4 Location Information</h3>
                    <ul>
                        <li><strong>Geolocation Data:</strong> We may collect and use information about your location to provide certain features and services.</li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <p>
                        We use your information to:
                    </p>
                    <ul>
                        <li>Provide, operate, and maintain our services.</li>
                        <li>Process transactions and send you related information, including purchase confirmations and invoices.</li>
                        <li>Communicate with you, including responding to your comments, questions, and requests.</li>
                        <li>Improve and personalize our services.</li>
                        <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
                        <li>Detect, prevent, and address technical issues and fraudulent activities.</li>
                    </ul>

                    <h2>3. How We Share Your Information</h2>
                    <p>
                        We do not share your personal information with third parties except in the following circumstances:
                    </p>
                    <ul>
                        <li><strong>With Your Consent:</strong> We may share or disclose your information with your consent.</li>
                        <li><strong>Service Providers:</strong> We may share your information with third-party vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
                        <li><strong>Legal Requirements:</strong> We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests.</li>
                    </ul>

                    <h2>4. Data Security</h2>
                    <p>
                        We implement a variety of security measures to maintain the safety of your personal information. However, no electronic transmission or storage of information is 100% secure, so we cannot guarantee absolute security.
                    </p>

                    <h2>5. Your Rights</h2>
                    <p>
                        Depending on your location, you may have the following rights regarding your personal information:
                    </p>
                    <ul>
                        <li><strong>Access:</strong> You can request access to your personal data.</li>
                        <li><strong>Correction:</strong> You can request correction of inaccurate or incomplete data.</li>
                        <li><strong>Deletion:</strong> You can request deletion of your personal data.</li>
                        <li><strong>Objection:</strong> You can object to the processing of your personal data.</li>
                        <li><strong>Restriction:</strong> You can request restriction of processing of your personal data.</li>
                        <li><strong>Portability:</strong> You can request the transfer of your personal data to another service provider.</li>
                    </ul>

                    <h2>6. Children's Privacy</h2>
                    <p>
                        Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete that information promptly.
                    </p>

                    <h2>7. Changes to This Privacy Policy</h2>
                    <p>
                        We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last Updated" date at the top of this policy.
                    </p>

                    <h2>8. Contact Us</h2>
                    <p>
                        If you have any questions about this privacy policy, please contact us.
                    </p>
                </div>
                <Modal show={show}>
                    <Update onClose={handleClose} />
                </Modal>
                <Modal show={show2}>
                    <Delete onClose={handleClose2} />
                </Modal>


            </div>
        </>

    )
}

export default Index