import { createSlice } from "@reduxjs/toolkit"
import { getMerchantBanks, getMerchantCards, getMerchantWalletBalance } from './BWAction'


interface BWStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface BalanceInterafce {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface PayloadAction {
    payload: any
}

const initialState: BWStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const initialBalanceState: BalanceInterafce = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const BWSlice = createSlice({
    name: 'bankAndWallet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchantBanks.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getMerchantBanks.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getMerchantBanks.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
            builder
            .addCase(getMerchantWalletBalance.pending, (state) => {
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getMerchantWalletBalance.fulfilled, (state, action: PayloadAction) => {
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data

            })
            .addCase(getMerchantWalletBalance.rejected, (state, action: PayloadAction) => {
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
        // builder
        //     .addCase(setMerchantProfile.pending, (state) => {
        //         state.loading = true;
        //         state.message = "";
        //         state.success = false;
        //         state.error = false
        //         state.data = null
        //     })
        //     .addCase(setMerchantProfile.fulfilled, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload.message
        //         state.success = true;
        //         state.error = false
        //         state.data = action.payload.data

        //     })
        //     .addCase(setMerchantProfile.rejected, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload
        //         state.success = false;
        //         state.error = true
        //         state.data = null

        //     })
        // builder
        //     .addCase(getAdminProfile.pending, (state) => {
        //         state.loading = true;
        //         state.message = "";
        //         state.success = false;
        //         state.error = false
        //         state.data = null
        //     })
        //     .addCase(getAdminProfile.fulfilled, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload.message
        //         state.success = true;
        //         state.error = false
        //         state.data = action.payload.data

        //     })
        //     .addCase(getAdminProfile.rejected, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload
        //         state.success = false;
        //         state.error = true
        //         state.data = null

        //     })
        // builder
        //     .addCase(setAdminProfile.pending, (state) => {
        //         state.loading = true;
        //         state.message = "";
        //         state.success = false;
        //         state.error = false
        //         state.data = null
        //     })
        //     .addCase(setAdminProfile.fulfilled, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload.message
        //         state.success = true;
        //         state.error = false
        //         state.data = action.payload.data

        //     })
        //     .addCase(setAdminProfile.rejected, (state, action: PayloadAction) => {
        //         state.loading = false;
        //         state.message = action.payload
        //         state.success = false;
        //         state.error = true
        //         state.data = null

        //     })

    },
})

export default BWSlice.reducer