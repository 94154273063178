import Button from 'react-bootstrap/Button';
import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import identityInstance from '../../../services/instances/identity.instance';

interface UpdateProps {
  setCardLoader: any;
  onClose: () => void;
  cardLoader: boolean

}


const stripePromise = loadStripe('pk_test_RVMvGgjuykyKTd6nCSPqrPlD');

// create addCard Component
const CheckoutForm = ({ setIsCardEdit }: any) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the client secret from your backend (replace with actual fetch)
    setLoading(true)
    const fetchClientSecret = async () => {
      try {

        let response: any = await identityInstance.post(`/merchants/create-setup-intent/${localStorage.getItem("customerId")}`);
        // const data = await response.json();

        const data: any = response.data;
        setClientSecret(data.client_secret);
        setLoading(false);
      } catch (err: any) {
      }
    };

    fetchClientSecret();
  }, []);

  const stripe: any = useStripe();
  const elements: any = useElements()

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't loaded yet, return early.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Confirm card setup
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        },
      },
    });

    if (error) {
      console.error(error);
    } else {
      setIsCardEdit(true)
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (

    <div className="payment-form-container">
      <form className="payment-form" onSubmit={handleSubmit}>
        <label htmlFor="cardholder-name">Cardholder Name</label>
        <input
          id="cardholder-name"
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          style={{ margin: '0.5rem 0', padding: '0.5rem', width: '100%' }}
        />
        <label>
          Card Number
          <CardElement className="StripeElement" />
        </label>
        <button type="submit" disabled={!stripe}>
          {!stripe ? <div className="spinner-border" role="status">
            <span className="sr-only p-0"></span>
          </div> : "Add Card"}
        </button>
      </form>
    </div >
  );
};



const AddCard: React.FC<UpdateProps> = ({ onClose, setCardLoader, cardLoader }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);
  const [isCardEdit, setIsCardEdit] = React.useState<boolean>(false)

  useEffect(() => {
    if (isCardEdit) {
      setModalStep(3)
    }
  }, [isCardEdit])

  const handleClose = () => {
    onClose();
    setCardLoader(!cardLoader)
  };

  const handleNextStep = () => {
    setModalStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setModalStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <Elements stripe={stripePromise}>
              <CheckoutForm setIsCardEdit={setIsCardEdit} />
            </Elements>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Security Verification</div>
            <div className="tac loginText otp">Enter the OTP sent to your registered mobile number with your card.</div>
            <div className="otpWrp">
              <form action="">
                <div className="otpSec">
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                </div>
              </form>
            </div>
            <Button className="loginBtn" onClick={handleNextStep}>Verify</Button>
            <p className="dint">
              Didn’t Receive? <a href="#" className="link">Send Again</a>
            </p>
          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="tac sucessfull">
              <span className="material-icons-outlined">verified</span>
            </div>
            <div className="popupHd tac">Card Added Successfully!</div>
            {/* <Button className="loginBtn" onClick={handleClose}>Close</Button> */}
          </div>
        )}
      </div>
    </>
  );
};

export default AddCard;
