import { merchantSignupInterface, adminLoginInterface, forgetPasswordInterface, verifyAnswerInterface, verifyForgotPasswordOTPInterface, merchantProfileInterface } from "./interface/auth.interface"
import identityInstance from "./instances/identity.instance"
import { removeUser } from "./token.service";
import axios from 'axios'


// ______________________________________merchant signup___________________________________________

async function merchantSignup(data: merchantSignupInterface) {
  let response = await identityInstance.post("/merchants/registration", data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}



// ____________________________merchant get email verification OTP___________________________________

async function merchantSendEmailVerificationOTP(user_id: string) {
  let response = await identityInstance.get(`/merchants/email-verifications/${user_id}`);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________merchant email verification ___________________________________________

async function merchantEmailVerification(user_id: string, data: any) {
  let response = await identityInstance.post(`/merchants/handle-email-verificaitions/${user_id}`, data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________________merchant get phone verification OTP_____________________________________

async function merchantSendPhoneVerificationOTP(user_id: string) {
  let response = await identityInstance.get(`/merchants/mobile-verifications/${user_id}`);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________merchant phone verification ________________________________________

async function merchantPhoneVerification(user_id: string, data: any) {
  let response = await identityInstance.post(`/merchants/handle-mobile-verificaitions/${user_id}`, data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________merchant resend OTP ___________________________________________

async function merchantResendOTP(user_id: string) {
  let response = await identityInstance.get(`/merchants/signup/resend-otp/${user_id}`);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________________merchant login___________________________________________


async function merchantLogin(data: adminLoginInterface) {
  let response: any = await identityInstance.post("/merchants/login", data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________________merchant logout___________________________________________


async function merchantLogout() {
  let response = await identityInstance.delete("/merchants/logout");
  if (!response?.data?.error) {
    removeUser()
  }
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}




// ______________________________________get merchant profile details___________________________________________


async function getMerchantProfileDetails() {
  let response = await identityInstance.get("/merchants/profile/details");

  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________set merchant profile details___________________________________________


async function setMerchantProfileDetails(data: merchantProfileInterface) {
  let response = await identityInstance.patch("/merchants/profile/details", data);

  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________________change merchant password___________________________________________


async function changeMerchantPassword(data: any) {
  let response = await identityInstance.post("/merchants/settings/profile/change-password", data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// __________________________________get merchant profile picture____________________________________



async function getMerchantProfileImage() {
  let response = await identityInstance.get("/merchants/image/path", {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}



// __________________________________upload merchant profile picture____________________________________


async function uploadMerchantProfileImage(file: File) {
  const formData = new FormData()
  formData.append("image", file)
  let response = await identityInstance.post("/merchants/upload/profile-picture", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ____________________________________merchant forget password_______________________________________

async function merchantForgotPassword(data: forgetPasswordInterface) {
  let response = await identityInstance.post("/merchants/forgot/password", data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// ______________________________get security question__________________________________

async function getSecurityQuestion() {
  let response = await identityInstance.get('/users/signup/list-security-questions');

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// ______________________________merchant Verify security question__________________________________

async function merchantVerifyAnswer(dataWithParams: verifyAnswerInterface) {
  let data = {
    security_answer: dataWithParams.security_answer
  }
  let response = await identityInstance.post(`/merchants/forgot/password/verify/security-answer/${dataWithParams.user_id}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}




// ______________________________merchant Verify OTP__________________________________

async function merchantVerifyForgotPasswordOTP(dataWithParams: verifyForgotPasswordOTPInterface) {
  let data = {
    ew_otp: dataWithParams.ew_otp
  }
  let response = await identityInstance.post(`/merchants/forgot/password/otp-verification/${dataWithParams.user_id}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________merchant reset password__________________________________

async function merchantResetPassword(data: any, token: any) {
  let response = await axios.post(`${process.env.REACT_APP_IDENTITY_BASE_URL}/merchants/reset/password?token=${token}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________merchant add KYC__________________________________

async function addKYC(data: any) {
  let response = await identityInstance.post(`/merchants/kyc/submit`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________merchant add KYC document__________________________________

async function addKYCDocuments(file: { file: File, image: File, id_type: string }) {
  const formData = new FormData()
  formData.append("file", file.file)
  formData.append("image", file.image)
  formData.append("id_type", file.id_type)
  let response = await identityInstance.post(`/merchants/kyc/submit/upload/documents`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________merchant KYC status__________________________________

async function getKYCStatus() {
  let response = await identityInstance.get(`/merchants/kyc/status`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// ______________________________merchant KYC status__________________________________

async function getKYCDetails() {
  let response = await identityInstance.get(`/merchants/kyc/details`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________Add Bank Details __________________________________

async function addMerchantBank(data: any) {
  // const { userId, status } = params
  let response = await identityInstance.post(`/merchants/add/bank`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Get Banks List __________________________________

async function getMerchantBank() {
  let response = await identityInstance.get(`/merchants/banks`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________Set as Default Bank  __________________________________

async function setMerchantBankDefault(data: any) {
  let response = await identityInstance.post(`/merchants/set-default-account`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Delete Bank __________________________________

async function deleteMerchantBank(data: any) {
  let response = await identityInstance.delete(`/merchants/bank`, { data });
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Get Cards List __________________________________

async function getMerchantCard(data: any) {
  let response = await identityInstance.post(`/merchants/cards`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Set as Default Card  __________________________________

async function setMerchantCardDefault(data: any) {
  let response = await identityInstance.post(`/merchants/payment-methods/default`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Delete Card __________________________________

async function deleteMerchantCard(data: any) {
  let response = await identityInstance.delete(`/merchants/remove-card`, { data });
  try {
    return response.data
  } catch (err) {
    return err
  }
}






// _____________________________ Get Wallet Balance __________________________________

async function getMerchantWalletBalance() {
  let response = await identityInstance.get(`/merchants/wallet/balance`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________ Get Wallet Balance __________________________________

async function sendMerchantMoney(data: any) {
  let response = await identityInstance.post(`/merchants/transfer-money`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}



// _____________________________ Add money to merchant wallet __________________________________

async function addMoneyToMerchantWallet(data: any) {
  let response = await identityInstance.post(`/merchants/add-money`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


//----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------
// -----------------------------------------ADMIN SERVICE----------------------------------------
//----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------



// ______________________________________admin login___________________________________________


async function adminLogin(data: adminLoginInterface) {
  let response = await axios.post(`${process.env.REACT_APP_IDENTITY_BASE_URL}/admin/login`, data);

  try {
    return response.data;
  } catch (err) {
    return err;
  }
}



// ______________________________________admin logout___________________________________________


async function adminLogout() {
  let response = await identityInstance.delete("/admin/logout");
  if (!response.data.error) {
    removeUser()
  }
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}



// ____________________________________admin forget password_______________________________________

async function adminForgotPassword(data: forgetPasswordInterface) {
  let response = await axios.post(`${process.env.REACT_APP_IDENTITY_BASE_URL}/admin/forgot/password`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// ______________________________admin Verify security question__________________________________

async function adminVerifyAnswer(dataWithParams: verifyAnswerInterface) {
  let data = {
    security_answer: dataWithParams.security_answer
  }
  let response = await identityInstance.post(`/admin/forgot/password/verify/security-answer/${dataWithParams.user_id}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________admin Verify OTP__________________________________

async function adminVerifyForgotPasswordOTP(dataWithParams: verifyForgotPasswordOTPInterface) {
  let data = {
    ew_otp: dataWithParams.ew_otp
  }
  let response = await identityInstance.post(`/admin/forgot/password/otp-verification/${dataWithParams.user_id}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// ______________________________________get admin profile details___________________________________________


async function getAdminProfileDetails() {
  let response = await identityInstance.get("/admin/profile/details");

  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________set admin profile details___________________________________________


async function setAdminProfileDetails(data: merchantProfileInterface) {
  let response = await identityInstance.patch("/admin/profile/details", data);

  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// __________________________________get admin profile picture____________________________________



async function getAdminProfileImage() {
  let response = await identityInstance.get("/admin/image/path", {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// __________________________________upload admin profile picture____________________________________


async function uploadAdminProfileImage(file: File) {
  const formData = new FormData()
  formData.append("image", file)
  let response = await identityInstance.post("/admin/upload/profile-picture", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}

// ______________________________________change admin password___________________________________________


async function changeAdminPassword(data: any) {
  let response = await identityInstance.post("/admin/settings/profile/change-password", data);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________________merchant resend OTP ___________________________________________

async function adminResendOTP(user_id: string) {
  let response = await identityInstance.get(`/admin/signup/resend-otp/${user_id}`);
  try {
    return response.data;
  } catch (err) {
    return err;
  }
}


// ______________________________admin reset password__________________________________

async function adminResetPassword(data: any, token: any) {
  let response = await axios.post(`${process.env.REACT_APP_IDENTITY_BASE_URL}/admin/reset/password?token=${token}`, data);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________admin (get merchants kyc details) __________________________________

async function getMerchantsKYCDetail(params: any) {
  const { page, size, search, status } = params
  let response = await identityInstance.get(`/admin/kyc/merchant/list?search=${search}&page=${page}&page_size=${size}&status=${status}`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// ______________________________admin (get user kyc details) __________________________________

async function getUsersKYCDetail(params: any) {
  const { page, size, search, status } = params
  let response = await identityInstance.get(`/admin/kyc/users/list?search=${search}&page=${page}&page_size=${size}&status=${status}`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________change merchant kyc status __________________________________

async function changeMerchantKYCStatus(params: any) {
  const { userId, status } = params
  let response = await identityInstance.get(`/admin/kyc/change/merchant/status/${userId}?action=${status}`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________change user kyc status __________________________________

async function changeUserKYCStatus(params: any) {
  const { userId, status } = params
  let response = await identityInstance.get(`/admin/kyc/change/user/status/${userId}?action=${status}`);

  try {
    return response.data
  } catch (err) {
    return err
  }
}




const identityService = {
  merchantSignup,
  merchantSendEmailVerificationOTP,
  merchantEmailVerification,
  merchantSendPhoneVerificationOTP,
  merchantPhoneVerification,
  merchantResendOTP,
  merchantLogin,
  merchantLogout,
  getMerchantProfileDetails,
  setMerchantProfileDetails,
  changeMerchantPassword,
  getMerchantProfileImage,
  uploadMerchantProfileImage,
  merchantForgotPassword,
  getSecurityQuestion,
  merchantVerifyAnswer,
  merchantVerifyForgotPasswordOTP,
  merchantResetPassword,
  addKYC,
  addKYCDocuments,
  getKYCStatus,
  getKYCDetails,
  addMerchantBank,
  getMerchantBank,
  setMerchantBankDefault,
  deleteMerchantBank,
  getMerchantCard,
  setMerchantCardDefault,
  deleteMerchantCard,
  getMerchantWalletBalance,
  sendMerchantMoney,
  addMoneyToMerchantWallet,

  adminLogin,
  adminLogout,
  adminForgotPassword,
  adminVerifyAnswer,
  adminVerifyForgotPasswordOTP,
  getAdminProfileDetails,
  setAdminProfileDetails,
  getAdminProfileImage,
  uploadAdminProfileImage,
  changeAdminPassword,
  adminResendOTP,
  adminResetPassword,
  getMerchantsKYCDetail,
  getUsersKYCDetail,
  changeMerchantKYCStatus,
  changeUserKYCStatus
};

export default identityService;
