import React, { useEffect, useState } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Admin/AdminLeftMenu';
import { NavLink, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminProfile } from '../../../redux/features/profile/profileAction';
import { AppDispatch } from "../../../redux/store"
import { profileState, passwordInterface } from '../../../services/interface/profile.interface';
import identityService from '../../../services/identityService';
import PopUp from '../../../component/PopUp';
import { Modal } from 'react-bootstrap';
import { error } from 'console';
import { getUser, removeUser } from '../../../services/token.service';
import api from '../../../services/identityService';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import EmailVerify from '../../../component/Merchant/MerchantEmailVerify';
var _ = require('lodash');


const initialState: profileState = {
  ew_first_name: "",
  ew_last_name: "",
  ew_phone_number: "",
  ew_email: "",
  ew_bio: "",
  imagePreview: "",
  image: "",
  profilesErrors: {}
};

const initialPasswordState: passwordInterface = {
  old_password: "",
  new_password: "",
  confirm_new_password: "",
  PasswordErrors: {}
}


const AdminSettings = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch()
  const profileData = useSelector((state: any) => state.profile)
  const [profileDataState, setProfieDataState] = useState<profileState>(initialState)
  const [passwordState, setPasswordState] = useState<passwordInterface>(initialPasswordState)
  const [loader, setLoader] = useState<boolean>(false)
  const [imageLoader, setImageLoader] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [profileImage, setProfileimage] = useState("")
  const [profileImageLoader, setProfileImageLoader] = useState<boolean>(false)
  // const [emailVerifyModel, setEmailVerifyModel] = useState<boolean>(false)
  const user = getUser()
  const { ew_first_name, ew_last_name, ew_phone_number, ew_email, ew_bio, imagePreview, image, profilesErrors } = profileDataState
  const { old_password, new_password, confirm_new_password, PasswordErrors } = passwordState

  // const handleCloseEmailVerifyModal = () => setEmailVerifyModel(false);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const [showPassword1, setShowPassword1] = useState(false);
  const [isActive1, setIsActive1] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
    setIsActive1(!isActive1);
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
    setIsActive2(!isActive2);
  };

  const [showPassword3, setShowPassword3] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
    setIsActive3(!isActive3);
  };

  // handle form validation
  let handleValidation = () => {
    let firstNameEmpty = "";
    let lastNameEmpty = "";
    let formIsValid = true;
    if (!ew_first_name.trim()) {
      firstNameEmpty = "Please enter first name.";
      formIsValid = false;
    }

    if (!ew_last_name.trim()) {
      lastNameEmpty = "Please enter last name.";
      formIsValid = false;
    }

    setProfieDataState({
      ...profileDataState,
      profilesErrors: {
        firstNameEmpty,
        lastNameEmpty,
        formIsValid,
      },
    });
    return formIsValid;
  };

  let handlePasswordValidation = () => {
    let oldPasswordEmpty = "";
    let newPasswordEmpty = "";
    let confirmNewPasswordEmpty = "";
    let formIsValid = true;
    if (!old_password.trim()) {
      oldPasswordEmpty = "Please enter old password.";
      formIsValid = false;
    }

    if (!new_password.trim()) {
      newPasswordEmpty = "Please enter new password.";
      formIsValid = false;
    }

    if (!confirm_new_password.trim()) {
      confirmNewPasswordEmpty = "Please enter confirm new password.";
      formIsValid = false;
    }

    setPasswordState({
      ...passwordState,
      PasswordErrors: {
        oldPasswordEmpty,
        newPasswordEmpty,
        confirmNewPasswordEmpty,
        formIsValid,
      },
    });
    return formIsValid;
  };

  const handleProfileFormValue = (e: any) => {
    const { name, value } = e.target
    setProfieDataState({
      ...profileDataState,
      [name]: value
    })
  }

  const handlePasswordFormValue = (e: any) => {
    const { name, value } = e.target

    setPasswordState({
      ...passwordState,
      [name]: value
    })
  }


  useEffect(() => {
    const handleGetProfieData = async () => {
      setProfileImageLoader(true)
      await dispatch(getAdminProfile())
      identityService.getAdminProfileImage().then((res) => {
        if (!res.error) {
          setProfileimage(res.data.profile_picture_path)
          setProfileImageLoader(false)
        }
      })
      setProfileImageLoader(false)
    }
    handleGetProfieData()

  }, [setProfieDataState])

  const handleLogout = async () => {
    const user = getUser()
    if (user.userType === "Admin") {
      const res = await api.adminLogout()
      if (!res?.error) {
        navigate("/")
        navigate(0)
      }
    }
    else {
      removeUser()
      navigate("/")
      navigate(0)
    }


  }


  const handleSumbitProfile = async (e: any) => {
    e.preventDefault()
    const isFormValid = handleValidation()
    if (!isFormValid) {
      return
    }
    setLoader(true)
    const data = {
      ew_first_name,
      ew_last_name,
      ew_phone_number: null,
      ew_email: null,
      ew_bio,
    }
    identityService.setAdminProfileDetails(data).then((data: any) => {
      dispatch(getAdminProfile())
      setLoader(false)
      setPopup({ show: true, message: data.message })
    }).catch((err: any) => {
      console.log(err)
      setLoader(false)
    })
  }

  const handleSumbitPassword = async (e: any) => {
    e.preventDefault()
    const isFormValid = handlePasswordValidation()
    if (!isFormValid) {
      return
    }

    if (new_password === confirm_new_password) {
      setLoader(true)
      const data = {
        old_password,
        new_password,
        confirm_new_password
      }


      identityService.changeAdminPassword(data).then((data: any) => {
        setPopup({ show: true, message: data.message })
        setLoader(false)
        handleLogout()
      }).catch((err: any) => {
        console.log(err)
        setPopup({ show: true, message: err.message })
        setLoader(false)
      })

    } else {
      alert("your password not match with confirm password")
    }
  }

  useEffect(() => {
    if (profileData.success) {
      setProfieDataState(profileData.data);
    }
  }, [profileData])

  const fileUpload = (e: any) => {
    const file = e.target.files[0];
    const test = e.target.files[0];
    if (test) {
      if (
        test.type === "image/jpeg" ||
        test.type === "image/jpg" ||
        test.type === "image/png"
      ) {
        setImageLoader(true)
        identityService.uploadAdminProfileImage(file).then((res: any) => {
          setPopup({ show: true, message: res.message })
          setImageLoader(false)
        }).catch((error) => {
          setPopup({ show: true, message: error.message })
        })
      } else {
        setPopup({ show: true, message: "Only formats are allowed: jpeg, jpg, png" })
      }
    }
  };

  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Settings</div>
        <div className="whtBox p0">
          <Tabs defaultActiveKey="Account Setting" transition={false} id="Settings" className="mb-3">
            <Tab eventKey="Account Setting" title="Account Setting">
              <div id="accountSetting" className="contentBx">
                <div className="flexBox settingFormBox">
                  <div className="settingImg">
                    {profileData?.loading ? <Skeleton circle={true} height={150} /> : imageLoader ? <div className="d-flex justify-content-center align-items-center" style={{ width: "10rem", height: '10rem' }}> <div className="spinner-border" role="status">
                      <span className="sr-only p-0"></span>
                    </div> </div> :
                      <>
                        <img src={profileImage ? profileImage : "/images/placeholder.png"} alt="profile image" />
                        <a className="addIcon">
                          <input onChange={fileUpload} type="file" className="file" />
                          <span className="material-icons-outlined">add_a_photo</span>
                        </a>
                      </>
                    }
                  </div>
                  <form onChange={handleProfileFormValue} onSubmit={handleSumbitProfile} className="settingDet">
                    <ul className="flexBox p-0">
                      <li>
                        <span className="fieldHd">First Name</span>
                        {profileData?.loading ? <Skeleton height={50} /> :
                          <>
                            <input value={ew_first_name} type="text" className="formInput" name="ew_first_name" />
                            <span style={{ color: "red" }}>
                              {profilesErrors && profilesErrors?.firstNameEmpty}
                            </span>
                          </>
                        }

                      </li>
                      <li>
                        <span className="fieldHd">Last Name</span>

                        {profileData?.loading ? <Skeleton height={50} /> :
                          <>
                            <input value={ew_last_name} type="text" className="formInput" name="ew_last_name" />
                            <span style={{ color: "red" }}>
                              {profilesErrors && profilesErrors?.lastNameEmpty}
                            </span>
                          </>
                        }

                      </li>
                      <li>
                        <span className="fieldHd">Email Address</span>

                        {profileData?.loading ? <Skeleton height={50} /> :
                          <>
                            <input readOnly value={ew_email} type="text" className="formInput" name="ew_email" />
                          </>
                        }

                      </li>
                      <li>
                        <span className="fieldHd">Phone Number</span>

                        {profileData?.loading ? <Skeleton height={50} /> :
                          <>
                            <input readOnly value={ew_phone_number} type="text" className="formInput" name="ew_phone_number" />
                          </>
                        }

                      </li>
                      <li className="full">
                        <span className="fieldHd">Bio</span>

                        {profileData?.loading ? <Skeleton height={150} /> :
                          <>
                            <textarea name="ew_bio" className="formInput" defaultValue={ew_bio}></textarea>
                          </>
                        }

                      </li>
                      <li className="full"><Button disabled={loader} type="submit" className="loginBtn">{loader ? "Loading..." : "Update"}</Button></li>
                    </ul>
                  </form>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Password" title="Password">
              <div id="updatePassword" className="contentBx">
                <form onChange={handlePasswordFormValue} onSubmit={handleSumbitPassword} className="passwordSetting">
                  <ul className="flexBox p-0">
                    <li>
                      <span className="fieldHd">Old Password</span>
                      <div className="pr">
                        <input name="old_password" placeholder="Enter New Password" className="formInput password" type={showPassword1 ? 'text' : 'password'}
                          defaultValue={old_password} />
                        <span className="material-icons-outlined eye" onClick={togglePasswordVisibility1}>
                          {isActive1 ? 'visibility_off' : 'visibility'}
                        </span>
                      </div>
                      <span style={{ color: "red" }}>
                        {PasswordErrors && PasswordErrors?.oldPasswordEmpty}
                      </span>
                    </li>
                    <li>
                      <span className="fieldHd">New Password</span>
                      <div className="pr">
                        <input name="new_password" placeholder="Enter New Password" className="formInput password" type={showPassword2 ? 'text' : 'password'}
                          defaultValue={new_password} />
                        <span className="material-icons-outlined eye" onClick={togglePasswordVisibility2}>
                          {isActive2 ? 'visibility_off' : 'visibility'}
                        </span>
                      </div>
                      <span style={{ color: "red" }}>
                        {PasswordErrors && PasswordErrors?.newPasswordEmpty}
                      </span>
                    </li>
                    <li>
                      <span className="fieldHd">Confirm Password</span>
                      <div className="pr">
                        <input name="confirm_new_password" placeholder="Enter New Password" className="formInput password" type={showPassword3 ? 'text' : 'password'}
                          defaultValue={confirm_new_password} />
                        <span className="material-icons-outlined eye" onClick={togglePasswordVisibility3}>
                          {isActive3 ? 'visibility_off' : 'visibility'}
                        </span>
                      </div>
                      <div className="erroeMessage" style={{ display: "none" }}>Old Password not matched.</div>
                      <span style={{ color: "red" }}>
                        {PasswordErrors && PasswordErrors?.confirmNewPasswordEmpty}
                      </span>
                    </li>
                    <li><Button disabled={loader} type="submit" className="loginBtn">{loader ? "Loading..." : "Update"}</Button></li>
                  </ul>
                </form>
              </div>
            </Tab>
          </Tabs>

        </div>
      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>

  )
}

export default AdminSettings